<template>
  <div class="has-nav-bar view-user-help">
    <van-nav-bar
      title="帮助"
      :border="false"
      fixed
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="user-help-container">
      帮助
    </div>
  </div>
</template>

<script>
import {
  CellGroup,
  Cell,
  Tag,
  Row,
  Col,
  Button,
  NavBar,
  Dialog,
  Toast,
  Icon
} from "vant";
import { mapActions } from "vuex";
export default {
  name: "UserHelp",
  components: {
    [Tag.name]: Tag,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Dialog.name]: Dialog,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Row.name]: Row
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>
<style lang="scss">
.view-user-help {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .user-help-container {
    margin: 0 52px;
    height: 100%;
    position: relative;
  }
}
</style>
